import $ from 'jquery';

export const constants = {
  table: {
    limit: 10,
    pagination: 5
  }
};

export const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const mdash = '<span class="gray">&mdash;</span>';

export function unescapeHtml(text) {
  return $('<textarea />').html(text).val();
}

export function escapeHtml(text) {
  return unescapeHtml(text)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
}

export function atomizeLabel(text) {
  return typeof text == 'string' ? text.split('-').join('').toLowerCase() : '';
}

export function jsonToValue(object) {
  let result = JSON.stringify(JSON.stringify(object));
  return result.substring(1, result.length - 1);
}

export function formToObject(form) {
  let result = {};
  $.each($(form).serializeArray(), (i, item) => {
    if (item.name.indexOf('[]') != -1) {
      result[item.name] = typeof result[item.name] == 'object' ?
        [...result[item.name], item.value] : [item.value];
    } else {
      result[item.name] = item.value;
    }
  });
  return result;
}

export function fromUTC(str) {
  return (new Date(str + ' UTC')).toString().substr(4, 20);
}

export function toUTC(date) {
  return (new Date(date).toISOString().replace(/T/, ' ').replace(/\..+/, ''))
}

export function createTextLink(text) {
  return `<a href="#" data-text="${encodeURIComponent(text)}">View</a>`;
}
