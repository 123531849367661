import $ from 'jquery';
import _ from 'lodash';

import * as common from './common';

class View {
  constructor() {
    $('#signin, #forgot, #reset').click(this.submitClick.bind(this));
  }

  /**
   * Handle form submission
   *
   * @param e  Event listener
   */
  submitClick(e) {
    e.preventDefault();
    this.validateForm()
      .then(() => {
        $('form').submit();
      }).catch(() => {
        console.error('Form validation error');
      });
  }

  /**
   * Validate form
   */
  validateForm() {
    $('input').removeClass('error');
    $('span.error').remove();
    return new Promise((resolve, reject) => {
      let result = true;
      let email = $('#email').length ? $('#email').val() : null;
      $('input.required, select.required').each((i, item) => {
        if ($(item).attr('name') == 'email'
            && !common.validateEmail.test($(item).val())) {
          $(item).addClass('error');
          $(item).siblings('label').append('<span class="error">Invalid Email Address</span>');
          result = false;
        } else if (!$(item).val().length) {
          $(item).addClass('error');
          $(item).siblings('label').append('<span class="error">Required</span>');
          result = false;
        }
      });
      if (email && !common.validateEmail.test(email)) {
        $('#email').addClass('error');
        $('#email').siblings('label').append('<span class="error">Invalid Email Address</span>');
        result = false;
      } else if ($('#password2').length) {
        if ($('#password').val() != $('#password2').val()) {
          $('#password, #password2').addClass('error');
          $('#password').siblings('label').append('<span class="error">Passwords Must Match</span>');
          result = false;
        }
      }
      result ? resolve() : reject();
    });
  }
}

$(function() {
  let view = new View();
});
